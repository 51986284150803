<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-col lg="8" class="d-none d-lg-flex">
        <div class="w-100 d-lg-flex justify-content-center">
          <div id="login-intro" style="flex: 1 1 0%; box-sizing: border-box;">
            <div class="logo">
              <img class="ng-tns-c25-5" src="/assets/shield-white.svg">
            </div>
            <div class="title ng-trigger ng-trigger-animate">
              Complete your <span class="ng-tns-c25-5" style="font-family: boxen-bold;">Secris</span> account
            </div>
            <div class="description ng-trigger ng-trigger-animate">
              Please choose your password
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            WELCOME TO SECRIS 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please choose a secure password below and we can get started
          </b-card-text>
          <!-- form -->
          <validation-observer ref="verifyValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            > <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="verify-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="verify-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="verify-password-confirm">Password (again)</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="PasswordAgain"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="verify-password-confirm"
                      v-model="passwordConfirm"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="verify-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Show login errors -->
              <b-alert v-model="showVerifyErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ verifyError }}</span>
                </div>
              </b-alert>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Need an account?  <b-link :to="{name:'auth-register'}">
              <small>Register</small>
            </b-link></span>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
  <!-- /Left Text-->
</template>

<script>

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import auth from '@/auth/auth'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  name: 'Verify',
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      verifyError: '',
      required,
      email,
      // validation rulesimport store from '@/store/index',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    showVerifyErrors() {
      return this.verifyError !== ''
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.verifyValidation.validate().then(success => {
        if (success) {
          auth.verify(this.token, {
            new_password: this.password,
            new_password_confirm: this.passwordConfirm,
          }).then(response => {
            const { userData } = response.data

            // Make sure they have some permissions
            if (userData.permissions == null) {
              this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
              return
            }

            auth.setToken(response.data.accessToken)
            auth.setCurrentUser(userData)
            this.$store.commit('user/SET_CURRENT_USER', userData);
            this.$ability.update(userData.permissions)

            this.$router.replace('/')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${userData.name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'You have successfully logged in. Now you can start to explore!',
                  },
                })
              })
              .catch(error => {
                this.verifyError = error.response.data.error
              })
          }, error => {
            if (error.response) {
              this.verifyError = error.response.data.error
            } else {
              this.verifyError = error.message
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
